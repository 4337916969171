import { absolutePath } from "./absolutePath";

interface IndexParams {
  page?: number;
  per_page?: number;
}

export const forms = {
  create: () => absolutePath("forms"),
  index: (params: IndexParams) => absolutePath("forms", params),
  show: (id: number) => absolutePath(`forms/${id}`),
  update: (id: number) => absolutePath(`forms/${id}`),
};
